<template>
  <div class="page">
    <van-list v-model:loading="loading" :finished="finished" finished-text="" @load="getData">
      <div class="s1 p-card">
        <no-data v-if="list.length===0" title="暂无生效群" :show-button="false" />
        <div v-for="(item,index) in list" :key="index" class="item flex">
          <div><div class="avatar"><img :src="item.chatroomImgUrl"></div></div>
          <div class="flex-1">
            <div class="p1">{{ item.chatroomName ? item.chatroomName : '未命名' }}</div>
            <div class="p2">群成员：{{ item.memberCount }}</div>
          </div>
          <div class="pl-2"><input v-model="item.isChecked" type="checkbox" class="switch" @change="itemChange(item)"></div>
        </div>
        <div class="tips">开启托管后的群，才可以设置入群欢迎语</div>
      </div>
    </van-list>
    <div class="x-padding-bottom" />
    <div class="p-footer h-end">
      <button class="btn auto gray" @click="submit(0)">关闭</button>
      <!--      <button class="btn auto fill ml-3" @click="submit(1)">保存</button>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Index',
  data() {
    return {
      list: [],
      chatroomList: [],
      pageIndex: 1,
      pageSize: 15,
      loading: false,
      finished: false
    }
  },
  computed: {
    ...mapGetters(['globalData', 'robotInfo'])
  },
  created() {
    const pick = this.globalData
    if (pick) {
      this.chatroomList = pick.chatroomList || []
    }
  },
  methods: {
    getData() {
      this.loading = true
      this.$post('/Common/GetChatrooms', {
        RobotWxIds: [this.robotInfo.robotWxId],
        SelectChatroomList: this.chatroomList.map(i => {
          return {
            robotWxId: i.robotWxId,
            chatroomId: i.chatroomId
          }
        }),
        // GroupTypes: 2,
        Type: 3,
        GroupName: '',
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
        SortName: '',
        SortOrder: 0
      }).then(res => {
        this.pageIndex++
        this.loading = false
        this.list = [...this.list, ...res.dataList]
        if (this.list.length >= res.recordCount) {
          this.finished = true
        }
      }).catch(() => {
        this.loading = false
        this.finished = true
      })
    },
    itemChange(item) {
      this.$post('/JoinChatroomGreet/JoinChatroomGreetChatroomSetting', {
        robotWxId: item.robotWxId,
        chatroomId: item.chatroomId,
        status: item.isChecked ? 1 : 0
      }).then(res => {
        this.$Toast('切换成功')
      })
    },
    submit(n) {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
